import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Main } from './pages/main/main';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UnAuth } from './pages/unauth/uauth';
import { initGA, logPageView } from './analytics';
import Banner from './components/Banners/Banners';
import { Home } from './pages/Home/Home';
import { Loader } from './pages/Loader/Loader';

function AnalyticsHandler() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Banner />
        <AnalyticsHandler />
        <Routes>
          <Route path="/redirect/:appid/:gateway_id/:hash" element={<Main />} />
          <Route path="/unauth" element={<UnAuth />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
