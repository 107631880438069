import React from 'react';
import Helmet from 'react-helmet';


const Banner: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Onepay Payment Gateway powered By Spemai</title>
        <meta
          name="description"
          content="Sri Lanka's trusted Online Payment Gateway Service that helps to streamline transactions and payments."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="-1" />
        <link rel="icon" type="image/x-icon" href="favicon.ico" />
        <link
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://spemai.com/" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Onepay Payment Gateway" />
        <meta
          property="og:description"
          content="Sri Lanka's trusted Online Payment Gateway Service."
        />
        <meta property="og:url" content="https://spemai.com/" />
        <meta property="og:image" content="https://storage.googleapis.com/service_banner/512.jpg" />
        <meta
          property="og:image:alt"
          content="Sri Lanka's trusted Online Payment Gateway Service."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="SPEMAI" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Onepay Payment Gateway" />
        <meta
          name="twitter:description"
          content="Sri Lanka's trusted Online Payment Gateway Service."
        />
        <meta name="twitter:url" content="https://spemai.com/" />
        <meta name="twitter:image" content="https://storage.googleapis.com/service_banner/512.jpg" />

        {/* Robots */}
        <meta name="robots" content="max-image-preview:large" />


      </Helmet>
    </>
  );
};

export default Banner;
