import { BsArrowRightShort } from "react-icons/bs"
import { Button } from "../../core/Button/Button"
import { post } from "../../hooks/posts_hook";
import { TransactionDetails } from "../../types/api.types";
import { useSelector } from "react-redux";
import { oneticketValidateAPI } from "../../services/oneticket.service";
import { toast } from "react-toastify";


export const Cybersource = () =>{
    const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);

const submitCybersource=async()=>{


  // if (paymentDetails.request_type == "TICKETING") {
    let oneticketData = {
      onepay_transaction_id: paymentDetails.gateway_transaction_id,
    };
  
    const validate_response: any = await oneticketValidateAPI(oneticketData);
    if (validate_response.status !== 200) {
      toast.error("Sorry you took too long to submit payment details.", { autoClose: 3000 });
  
      // Add a small delay before redirecting
      setTimeout(() => {
        window.location.href = paymentDetails?.redirect_url;
      }, 3000); // Adjust the delay as needed for your toast duration
  
      return;
    }else{
      let redirect_request = {
        onepay_transaction_id: paymentDetails.gateway_transaction_id
      };

      post(redirect_request, "https://onepay-cyber-checkout-live-dot-spemai-asia.el.r.appspot.com/api/v3/cybersource/redirect/");
    }

  
  // else{
  //   let redirect_request = {
  //     onepay_transaction_id: paymentDetails.gateway_transaction_id
  //   };

  //   post(redirect_request, "https://onepay-cyber-checkout-live-dot-spemai-asia.el.r.appspot.com/api/v3/cybersource/redirect/");
  // }
  
   
}
    return(
        <div>
                 <div className="ps-3 pe-3 pt-3">
        <Button
           buttonText={paymentDetails.action_bt_text}
          type="button"
          onClickHandler={() => submitCybersource()}
          icon={<BsArrowRightShort />}
          className="pay-btn"
        />
      </div>



        </div>
    )
}