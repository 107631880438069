import loader from '../../assets/loader2.gif';
export const Loader = () => {
  return (
    <div  className="d-flex justify-content-center align-items-center">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <img src={loader} />
        <p className='text-center'>Please wait....</p>
      </div>
      
    </div>
  );
};
