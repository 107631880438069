// import React, { useEffect, useState } from 'react';
// import { CardElement } from './CardElement/CardElement';
// import { CardOptions } from './CardOptions/CardOptions';
// import './CardPayments.scss';
// import { BsInfoCircle } from 'react-icons/bs';
// import { TransactionDetails } from '../../types/api.types';
// import { useSelector } from 'react-redux';

// export const CardPayments = () => {
//   const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);

//   const [htmlContent, setHtmlContent] = useState('');



//     // Define a function to handle the response
//     const handleResponse = (data:any) => {
//       console.log("visited",data)
//       if(data.status==1000){
//         setHtmlContent(data.data.html_page);
//       }
//       // Process the response and update the state
//        // Assuming e is the value you want to set
//     };

    
//   useEffect(() => {
//     if(!paymentDetails.is_live){


//     const script = document.createElement('script');
//     script.id = 'authenticate-payer-script';
//     script.innerHTML = `
//       var e = document.getElementById("threedsChallengeRedirectForm");
//       if (e) {
//         e.submit();
//         if (e.parentNode !== null) {
//           e.parentNode.removeChild(e);
//         }
//       }
//     `;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }else{
//     if (htmlContent) {
//       const scriptElement = document.createElement('script');
//       scriptElement.innerHTML = `
//         var e = document.getElementById("threedsFrictionLessRedirectForm");
//         if (e) {
//           e.submit();
//           if (e.parentNode !== null) {
//             e.parentNode.removeChild(e);
//           }
//         }
//       `;
//       document.body.appendChild(scriptElement);

//       return () => {
//         document.body.removeChild(scriptElement);
//       };
//     }
//   }
//   }, [htmlContent]);

//   return (
//     <div>
// {paymentDetails.is_recurring==true &&      <div className="ms-3 me-3 recurring-box">
//         <div className="d-flex recurring-header pb-2">
//           {' '}
//           <div className="pe-2">
//             <BsInfoCircle />
//           </div>{' '}
//           <div>Card recurring payment</div>
//         </div>
//         <div className="recurring-desc">
//           Authentication for the above-mentioned payments was supposed to come into effect from April 1, 2024
//         </div>
//       </div>}

// {!htmlContent &&  (!paymentDetails.assign_services.includes('CYBERSOURCE') && !paymentDetails.assign_services.includes('CYBERSOURCE_USD') && !paymentDetails.assign_services.includes('AMEX') && !paymentDetails.assign_services.includes('AMEX-USD')) &&     <div>
//         <CardElement response={handleResponse}/>
//       </div>}

// {!htmlContent && (paymentDetails.assign_services.includes('CYBERSOURCE') || paymentDetails.assign_services.includes('CYBERSOURCE_USD') || paymentDetails.assign_services.includes('AMEX') || paymentDetails.assign_services.includes('AMEX-USD')) &&      <div className='ps-3 pe-3'>
//         <CardOptions details={paymentDetails} response={handleResponse}/>
//       </div>}

//       {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
//     </div>
//   );
// };

import React, { useEffect, useState } from 'react';
import './CardPayments.scss';
import { BsInfoCircle } from 'react-icons/bs';
import { TransactionDetails, notifyDetails } from '../../types/api.types';
import { useSelector } from 'react-redux';
import { CardOptions } from './CardOptions/CardOptions';
import { CardElement } from './CardElement/CardElement';
import { Loader } from '../../pages/Loader/Loader';

export const CardPayments = () => {
  const is_s=false;
  const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);
  const successDetail: notifyDetails = useSelector(
    (state: any) => state.notify
  );
  const [formData, setFormData] = useState({
    actionUrl: '',
    creq: ''
  });

  const handleResponse = (data: any) => {
    if (data.status == 1000) {
      setFormData({
        actionUrl: data.data.acs_url,
        creq: data.data.acs_creq
      });
    }
  };

  useEffect(() => {
    if (formData.actionUrl) {
      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', formData.actionUrl);
      form.setAttribute('target', 'paymentIframe');

      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'creq');
      input.setAttribute('value', formData.creq);

      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  }, [formData]);

  return (
    <div>
      {paymentDetails.is_recurring==1 && (
        <div className="ms-3 me-3 recurring-box">
          <div className="d-flex recurring-header pb-2">
            <div className="pe-2">
              <BsInfoCircle />
            </div>
            <div>Card recurring payment</div>
          </div>
          <div className="recurring-desc">
            Authentication for the above-mentioned payments was supposed to come into effect from April 1, 2024.
          </div>
        </div>
      )}

      {!formData.actionUrl && !paymentDetails.assign_services.includes('CYBERSOURCE') && !paymentDetails.assign_services.includes('CYBERSOURCE-USD') && !paymentDetails.assign_services.includes('AMEX') && !paymentDetails.assign_services.includes('AMEX-USD') && (
        <div>
          {/* Replace with your CardElement component */}
          <CardElement response={handleResponse} />
        </div>
      )}

      {!formData.actionUrl && (paymentDetails.assign_services.includes('CYBERSOURCE') || paymentDetails.assign_services.includes('CYBERSOURCE-USD') || paymentDetails.assign_services.includes('AMEX') || paymentDetails.assign_services.includes('AMEX-USD')) && (
        <div className="ps-3 pe-3">
          {/* Replace with your CardOptions component */}
          <CardOptions details={paymentDetails} response={handleResponse} />
        </div>
      )}

      {formData.actionUrl && !successDetail.is_loading &&(
        <iframe name="paymentIframe" style={{ width: '100%', height: '55vh', border: 'none' }} />
      )}

      {successDetail.is_loading && <Loader/>}
    </div>
  );
};

