import { createApiInstance } from "./apiInstance";
import { apiCall } from "./apiCall";
import { Method } from "axios";

const merchantApi = createApiInstance(process.env.SERVER_BASE_URL || "https://merchant-api-live-v2.onepay.lk/api/ipg/");

export const merchantApiCall = (
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false,
): Promise<any> => {
  return apiCall(merchantApi, url, method, data, header, encrypt);
};
