import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize("G-W1FB6R2DKH");
};

export const logPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const logEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };
  