import { ButtonProps } from '../../types/core.types';
import './Button.scss';

export const Button = ({
  isDisabled,
  buttonText,
  onClickHandler,
  className,
  isLoading,
  type = 'button', // Default to 'button' if not specified
  style,
  icon,
  ariaLabel,
  children,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={isDisabled || isLoading} // Consider the button disabled also when loading
      className={`${className} ${isLoading ? 'loading' : ''}`}
      onClick={onClickHandler}
      style={style} // Apply any passed inline styles
      aria-label={ariaLabel || buttonText} // Use buttonText as fallback aria-label
    >
      {isLoading ? (<div className='d-flex justify-content-center align-items-center'>       <div className='pe-3'>Please Wait</div> <div className=" loader"></div></div>

      ) : (
        <>
          {/* Render icon if provided */}
          {children ? children : buttonText}
          {icon && <span className="button-icon">{icon}</span>}
        </>
      )}
    </button>
  );
};
