import { merchantApiCall } from "../utils/api/merchantApi";

export const GetTransactionDataAPI = async (transaction_id:string) => {
    try {
      const data = await merchantApiCall('gateway/verify-transaction/?redirect=' + transaction_id);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
