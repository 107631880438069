import { createApiInstance } from "./apiInstance";
import { apiCall } from "./apiCall";
import { Method } from "axios";

const frimiApi = createApiInstance(process.env.SERVER_FRIMI_URL || "http://payment-qplus.onepay.lk/");

export const qplusApiCall = (
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false
): Promise<any> => {
  return apiCall(frimiApi, url, method, data, header, encrypt,false);
};
