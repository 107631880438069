import React, { useEffect, useRef } from 'react';
import './HelaPay.scss'; // Import your stylesheet
import { TransactionDetails } from '../../types/api.types';
import { useSelector } from 'react-redux';
import { helapaySubmitAPI } from '../../services/helapay.service';
import { v4 as uuidv4 } from 'uuid';

interface PaymentObject {
  api_key: string;
  merchant_code: string;
  reference_id: string;
  descriptor: string;
  amount: string;
}

export const HelaPay: React.FC = () => {
  const unique = useRef(uuidv4()).current; // Use ref to keep unique constant
  const paymentDetails: TransactionDetails = useSelector(
    (state: any) => state.payment
  );

  useEffect(() => {
    const iframeContainer = document.getElementById('iframe-container');
    if (!iframeContainer) return;

    // Check if iframe already exists
    if (iframeContainer.querySelector('iframe')) return;

    // Payment object details
    const payment: PaymentObject = {
      api_key: 'FkjzUmwb-WxJo-7OG6-730u-sQzvgkiR6R55',
      merchant_code: 'op',
      reference_id: unique,
      descriptor: paymentDetails.app_name,
      amount: paymentDetails.amount,
    };

    // Handlers
    const onComplete = async () => {
      const payload = {
        onepay_transaction_id: paymentDetails.gateway_transaction_id,
        hela_pay_transaction_reference: unique,
      };
      await helapaySubmitAPI(payload);
      console.log('HelaPay button is ready!');
    };

    const onError = (error: string) => {
      console.error('Error rendering HelaPay button:', error);
    };

    // Generate the iframe content with embedded script and container
    const iframeContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>HelaPay iFrame</title>
        </head>
        <body>
          <div id="hp-container"></div>
          <script>
            (function() {
              const payment = ${JSON.stringify(payment)};
              const onComplete = ${onComplete.toString()};
              const onError = ${onError.toString()};

              // Load the HelaPay script
              const script = document.createElement('script');
              script.src = 'https://www.helapay.lk/sdk/helapay-1.0.0.min.js'; // Replace with actual script URL
              script.onload = () => {
                if (window.helapay && typeof window.helapay.generateLink === 'function') {
                  window.helapay.generateLink(payment, 'hp-container', onComplete, onError);
                } else {
                  console.error('HelaPay library is not available.');
                }
              };
              script.onerror = () => {
                console.error('Failed to load HelaPay script.');
              };
              document.body.appendChild(script);
            })();
          </script>
        </body>
      </html>
    `;

    // Create iframe
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '280px';
    iframe.style.border = 'none';
    iframe.src = `data:text/html;charset=utf-8,${encodeURIComponent(iframeContent)}`;

    iframeContainer.appendChild(iframe);
  }, [paymentDetails, unique]);

  return (
    <div>
      <div className="ps-3 pe-3 pt-3 d-flex justify-content-center" id="iframe-container">
        {/* iFrame will be dynamically appended here */}
      </div>
    </div>
  );
};
