import './QplusNotification.scss';
import frimi1 from '../../../assets/frimi1.jpeg';
import frimi2 from '../../../assets/frimi2.jpeg';
import frimi from '../../../assets/frimi_logo.png';
export const QplusNotification = () => {
  return (
    <div>
       
      <div className="row">
        <div className="col-6">
          <img src={frimi1} className="img-fluid" />
        </div>

        <div className="col-6">
          <img src={frimi2} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
