import './CardOptions.scss';
import master from '../../../assets/master_logo.png';
import visa from '../../../assets/visa_logo.png';
import amex from '../../../assets/express_logo.png';
import { useState } from 'react';
import { TransactionDetails } from '../../../types/api.types';
import { CardElement } from '../CardElement/CardElement';
import { Cybersource } from '../../Cybersource/Cybersource';
import { AmexOld } from '../../AmexOld/AmexOld';
export interface CardOptions {
  details: TransactionDetails;
  response: any;
}
export const CardOptions = ({ details, response }: CardOptions) => {
  const [type, setType] = useState('');

  const optionSelection = (type: string) => {
    setType(type);
  };

  return (
    <div>
      {type === '' && (
        <>
          <div className="row">
            <div className="col-6 ps-4 pe-4 pt-4">
              <div
                className="card-option d-flex justify-content-center align-items-center"
                onClick={() => optionSelection('mpgs')}
              >
                <img src={master} width={'37.57px'} alt="master" />
              </div>
            </div>

            <div className="col-6 ps-4 pe-4 pt-4">
              <div
                className="card-option d-flex justify-content-center align-items-center"
                onClick={() => optionSelection('mpgs')}
              >
                <img src={visa} width={'37.57px'} alt="visa" />
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-6 p-4">
              <div
                className="card-option d-flex justify-content-center align-items-center"
                onClick={() => optionSelection('amex')}
              >
                <img src={amex} width={'37.57px'} alt="amex" />
              </div>
            </div>
          </div>
        </>
      )}
      {type &&
        type === 'mpgs' &&
        !details.assign_services.includes('CYBERSOURCE') &&
        !details.assign_services.includes('CYBERSOURCE-USD') && (
          <CardElement response={response} />
        )}

      {type &&
        type === 'mpgs' &&
        (details.assign_services.includes('CYBERSOURCE') ||
          details.assign_services.includes('CYBERSOURCE-USD')) && (
          <Cybersource />
        )}

      {type &&
        type === 'amex' &&
        (details.assign_services.includes('NTB-AMEX-LKR') ||
          details.assign_services.includes('NTB-AMEX-USD')) && (
          <CardElement response={response} />
        )}


{type &&
        type === 'amex' &&
        (details.assign_services.includes('AMEX') ||
          details.assign_services.includes('AMEX-USD')) && (
          <AmexOld/>
        )}
    </div>
  );
};
