
import QRCode from 'react-qr-code';
import './LankaQR.scss';
import { TransactionDetails } from '../../types/api.types';
import { useSelector } from 'react-redux';
import { lankaqrGetAPI } from '../../services/lankaqr.service';
import { useEffect, useState } from 'react';
import { decodeBase64 } from '../../utils/hashing.utils';
import { Loader } from '../../pages/Loader/Loader';
import { toast } from 'react-toastify';
export const LankaQR = ()=>{
    const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);
    const [qrValue,setQRValue] = useState<any>("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        GetLankaQR()
    },[])

    const GetLankaQR = async() =>{
        setIsLoading(true)
        let data={
            onepay_transaction_id:paymentDetails.gateway_transaction_id
        }

        const lankaqr = await lankaqrGetAPI(data)
        if(lankaqr.status==201){
            setQRValue(decodeBase64(lankaqr.data.qr_code))
            setIsLoading(false)
            //console.log(decodeBase64(lankaqr.data.qr_code),"lankaqr")
        }else{
            toast.error("Error with LankaQR")
        }
       

    }
    return(
        <div>
      {!isLoading   &&   <div className='d-flex justify-content-center p-5'>
          <QRCode
    size={156}
    style={{ height: "auto", maxWidth: "90%", width: "100%" }}
    value={qrValue}
    viewBox={`0 0 256 256`}
  />
</div>}
{isLoading && <Loader/>}
        </div>
    )
}