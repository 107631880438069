import { frimiApiCall } from "../utils/api/frimiApi";



export const frimiSubmitAPI = async (pay_load:any,token:string,hash:string) => {
    try {
      const data = await frimiApiCall('transaction/frimi/?ha=' + hash,'POST',pay_load,token,false);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
