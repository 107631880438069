import './unauth.scss';
export const UnAuth = () => {
  return (
    <div className='gateway-container'>
      <div className="h-100 d-flex  align-self-center align-items-center flex-wrap justify-content-center">
        <p>
          <span className="bold">401</span> | You are not authorized
        </p>
      </div>
    </div>
  );
};
