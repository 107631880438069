import { configureStore } from "@reduxjs/toolkit";
import paymentReducer from "./features/paymentReducer";
import notifyReducer from "./features/notifyReducer";

export const store = configureStore({
    reducer: {
      payment: paymentReducer,
      notify:notifyReducer
    },
  });
  
