import { qplusApiCall } from "../utils/api/qplusApi";




export const qplusSubmitAPI = async (pay_load:any,token:string) => {
    try {
      const data = await qplusApiCall('qplus/1.0/transaction/','POST',pay_load,token,false);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
