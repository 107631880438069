import { useNavigate } from 'react-router-dom';
import bannerImg from '../../assets/512.jpg';
import gateway from '../../assets/OnepayPaymentRedirect.png';
import './Home.scss';

export const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="row ms-2 me-2">
      <div className="col-lg-6 col-12">
        <img className="img-fluid" src={bannerImg} alt="Banner" />
      </div>
      <div className="col-lg-6 col-12 d-flex align-items-center pt-5">
        <div>
          <img className="img-fluid" src={gateway} alt="Gateway" />
          <div className='pt-5 d-flex justify-content-center'>
            <div className="pay-btn register w-50" onClick={() => window.location.href = "http://onepay.lk"}>
              Register Now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
