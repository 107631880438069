import './main.scss';
import logo from '../../assets/onepay.png';
import secure from '../../assets/secure.png';
import { pay_types } from '../../utils/payment_types';
import { optionType } from '../../types/pages.types';
import { useEffect, useState } from 'react';
import { CardPayments } from '../../components/CardPayments/CardPayments';
import { BsArrowLeft } from 'react-icons/bs';
import { GetTransactionDataAPI } from '../../services/verifications.service';
import { useNavigate, useParams } from 'react-router-dom';
import { TransactionDetails, notifyDetails } from '../../types/api.types';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentDetails } from '../../redux/paymentActions';
import TransactionListener from '../../configs/firebaseConfig';
import success from '../../assets/success.png';
import fail from '../../assets/fail.png';
import { Frimi } from '../../components/Frimi/Frimi';
import { Vishwa } from '../../components/Vishwa/Vishwa';
import { useFadeIn } from '../../hooks/fade_hook';
import { Loader } from '../Loader/Loader';
import { getJsonHash } from '../../utils/hashing.utils';
import { LankaQR } from '../../components/LankaQR/LankaQr';
import { HelaPay } from '../../components/HelaPay/HelaPay';
import { Qplus } from '../../components/QPlus/Qplus';

export const Main = () => {
  const fadeIn = useFadeIn(0); // Adjust the delay as needed (in milliseconds)
  const successDetail: notifyDetails = useSelector(
    (state: any) => state.notify
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appid, gateway_id, hash } = useParams();
  const [selectedOption, setSelectedOption] = useState<optionType | null>();
  const [transactionData, setTransactionData] = useState<TransactionDetails>();
  const [isSucess, setIsSuccess] = useState<boolean>(false);
  const [isFail, setIsFail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function postRedirect(url: string, payload: any) {
    // Create a form element
    const form = document.createElement('form');

    // Set the form's method and action attributes
    form.method = 'POST';
    form.action = url;

    // Loop through the payload and create hidden input fields
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = payload[key];
        form.appendChild(input);
      }
    }

    // Append the form to the body
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  }

  useEffect(() => {
    getTransactionData();
  }, []);

  useEffect(() => {
    if (
      successDetail &&
      successDetail.is_pay === true &&
      transactionData?.redirect_url
    ) {
      const timer = setTimeout(() => {
        if (transactionData.is_sdk == 1) {
          let hash_request = {
            onepay_transaction_id: transactionData.gateway_transaction_id,
            merchant_transaction_id: transactionData.merchant_transaction_id,
            status: 1,
          };

          let redirect_request = {
            ...hash_request,
            hash: getJsonHash(hash_request),
          };
          postRedirect(transactionData.redirect_url, redirect_request);
        } else {
          window.location.href = transactionData?.redirect_url;
        }
      }, 5000); // 5000 milliseconds = 5 seconds

      // Clear timeout if the component unmounts or successDetail changes
      return () => clearTimeout(timer);
    }else if( successDetail &&
      successDetail.is_pay === false && successDetail.is_authenticated === true &&
      transactionData?.redirect_url){
      const timer = setTimeout(() => {
        if (transactionData.is_sdk == 1) {
          let hash_request = {
            onepay_transaction_id: transactionData.gateway_transaction_id,
            merchant_transaction_id: transactionData.merchant_transaction_id,
            status: 0,
          };

          let redirect_request = {
            ...hash_request,
            hash: getJsonHash(hash_request),
          };
          postRedirect(transactionData.redirect_url, redirect_request);
        } else {
          window.location.href = transactionData?.redirect_url;
        }
      }, 5000); // 5000 milliseconds = 5 seconds

      // Clear timeout if the component unmounts or successDetail changes
      return () => clearTimeout(timer);
    }
  }, [successDetail.is_pay, transactionData]);

  const getTransactionData = async () => {
    try {
      setIsLoading(true);
      const api_response: any = await GetTransactionDataAPI(
        gateway_id ? gateway_id : ''
      );

      if (api_response && api_response.status != 1001) {

        setTransactionData(api_response);
        dispatch(setPaymentDetails(api_response));
        setIsLoading(false);
      } else {
        navigate('/unauth');
      }
    } catch (e) {}
  };

  const optionClick = (types: optionType) => {
    setSelectedOption(types);
  };

  const serviceValidation = (arr1: Array<string>, arr2: Array<string>) => {
    return arr1.some((item1) => arr2.some((item2) => item1.includes(item2)));
  };


  const redirectToShopify=()=>{

    let url="https://shpy-onepay-dot-spemai-global.uc.r.appspot.com/app/void_session/?shop="+transactionData?.shop_name+"&access_token="+transactionData?.access_token+"&cancel_url="+transactionData?.cancel_url+"&id="+transactionData?.merchant_transaction_id
    var redirect_req={
        id:transactionData?.merchant_transaction_id,
        gid:transactionData?.merchant_transaction_id,
        paymentId:transactionData?.merchant_transaction_id,
        proposedAt:new Date().toUTCString,
    }
    postRedirect(url,redirect_req)
  }

  return (
    <div
      className={`main-container ${
        fadeIn ? 'fade-enter-active' : 'fade-enter'
      }`}
    >
      {isLoading && <Loader />}
      <TransactionListener
        transactionId={transactionData?.gateway_transaction_id ?? ''}
      />
      {!isLoading && (
        <>
          {' '}
          {successDetail.transaction_id === '' && (
            <div className="gateway-container">
              <div
                className="gateway-details p-3"
                style={{
                  backgroundColor:
                    transactionData?.banner_bg_color &&
                    transactionData?.banner_bg_color.includes('#')
                      ? transactionData?.banner_bg_color
                      : `#${transactionData?.banner_bg_color}`,
                }}
              >
                <div className="row mb-3">
                  <div className="col-6">
                    <img
                      className="logo-style"
                      src={
                        transactionData?.brand_logo
                          ? 'https://storage.googleapis.com/onepay_ipg/' +
                            transactionData?.brand_logo
                          : logo
                      }
                      alt={'logo'}
                    />
                  </div>
                  <div className="col-6">
                    <div
                      className="detail-txt pb-2"
                      style={{
                        color:
                          transactionData?.banner_font_color &&
                          transactionData?.banner_font_color.includes('#')
                            ? transactionData?.banner_font_color
                            : `#${transactionData?.banner_font_color}`,
                      }}
                    >
                      Total Payable

                    {transactionData?.is_shopify==1 && <button onClick={()=>redirectToShopify()}  className='badge badge-danger ms-4'>Cancel</button>}
                    </div>
                    <div
                      className="detail-value"
                      style={{
                        color:
                          transactionData?.banner_font_color &&
                          transactionData?.banner_font_color.includes('#')
                            ? transactionData?.banner_font_color
                            : `#${transactionData?.banner_font_color}`,
                      }}
                    >
                      {transactionData?.currency} : {Number(transactionData?.amount).toFixed(2)} 
                    </div>
                  </div>
                </div>
                <div className="details-border mb-2"></div>
                <div className="row">
                  <div className="col-6">
                    <div
                      className="detail-txt pb-2"
                      style={{
                        color:
                          transactionData?.banner_font_color &&
                          transactionData?.banner_font_color.includes('#')
                            ? transactionData?.banner_font_color
                            : `#${transactionData?.banner_font_color}`,
                      }}
                    >
                      Merchant Name
                    </div>
                    <div
                      className="detail-value"
                      style={{
                        color:
                          transactionData?.banner_font_color &&
                          transactionData?.banner_font_color.includes('#')
                            ? transactionData?.banner_font_color
                            : `#${transactionData?.banner_font_color}`,
                      }}
                    >
                      {transactionData?.merchant_brand_name}
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="detail-txt pb-2"
                      style={{
                        color:
                          transactionData?.banner_font_color &&
                          transactionData?.banner_font_color.includes('#')
                            ? transactionData?.banner_font_color
                            : `#${transactionData?.banner_font_color}`,
                      }}
                    >
                      Brand Name
                    </div>
                    <div
                      className="detail-value"
                      style={{
                        color:
                          transactionData?.banner_font_color &&
                          transactionData?.banner_font_color.includes('#')
                            ? transactionData?.banner_font_color
                            : `#${transactionData?.banner_font_color}`,
                      }}
                    >
                      {transactionData?.app_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="gateway-bottom d-flex align-items-end">
                <div className="gateway-block">
                  <div className="d-flex justify-content-between p-3">
                    {!selectedOption?.type && (
                      <div className="p-method">Select Payment Method</div>
                    )}
                    {selectedOption?.type && (
                      <div
                        style={{ cursor: 'pointer' }}
                        className="p-method d-flex"
                        onClick={() => setSelectedOption(null)}
                      >
                        <BsArrowLeft className="me-2" />
                        {selectedOption.type}
                      </div>
                    )}

                    <div>
                      <img className="s-img" src={secure} alt="secure" />
                    </div>
                  </div>
                  {!selectedOption?.type && (
                    <div>
                      {pay_types &&
                        pay_types.map((type: optionType, index: number) =>
                          serviceValidation(
                            transactionData?.assign_services ?? [],
                            type.services
                          ) ? (
                            <div onClick={() => optionClick(type)} key={index}>
                              <div
                                className={`row p-3 type-tile ${
                                  selectedOption?.type === type.type
                                    ? 'type-active'
                                    : ''
                                }`}
                              >
                                <div className="col-6 text-start type-txt d-flex align-items-center">
                                  {type.type}
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                  {type.image}
                                  {/* <img src={type.image} alt={type.type} /> */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )
                        )}
                    </div>
                  )}

                  {selectedOption?.type === 'Bank Credit/Debit' && (
                    <div>
                      <CardPayments />
                    </div>
                  )}

                  {selectedOption?.type === 'FriMi' && (
                    <div>
                      <Frimi />
                    </div>
                  )}

                  {selectedOption?.type === 'Sampath Vishwa' && (
                    <div>
                      <Vishwa />
                    </div>
                  )}

                  
{selectedOption?.type === 'Lanka QR' && (
                    <div>
                      <LankaQR />
                    </div>
                  )}


{selectedOption?.type === 'Hela Pay' && (
                    <div>
                      <HelaPay />
                    </div>
                  )}

{selectedOption?.type === 'Combank Q+' && (
                    <div>
                      <Qplus />
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center w-100 pay-footer">
                  <p className="mb-1">
                    © 2024 Copyright by OnePay. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          )}
          {successDetail &&
            successDetail.is_pay &&
            successDetail.transaction_id !== '' && (
              <div className="gateway-container2 p-5 d-flex align-items-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={success} alt="success" />
                  </div>
                  <div className="success-txt">
                    Payment completed successfully..!
                  </div>
                  <div className="success-amt">
                  {Number(transactionData?.amount).toFixed(2)} {transactionData?.currency}
                  </div>
                  <div className="success-desc">
                    {transactionData?.greeting_message
                      ? transactionData?.greeting_message
                      : 'Thank you for your purchase! Your payment has been successfully processed. You will receive a confirmation email shortly.'}
                  </div>
                  <div className="text-center pt-3 link-success">
                    <a href="https://app.oneid.ink/" target="_blank">
                      Download Receipt
                    </a>{' '}
                  </div>
                </div>
              </div>
            )}
          {successDetail &&
            !successDetail.is_pay &&
            successDetail.is_authenticated &&
            successDetail.transaction_id !== '' && (
              <div className="gateway-container2 p-5 d-flex align-items-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={fail} alt="fail" />
                  </div>
                  <div className="success-txt">Payment Failed!</div>
                  <div className="success-amt">
                    {transactionData?.amount} {transactionData?.currency}
                  </div>
                  <div className="success-desc">
                    We're sorry, but your payment could not be processed at this
                    time. Please check your payment details and try again.
                  </div>
                  <div className="text-center pt-3 link-success">
                    <a href="https://app.oneid.ink/" target="_blank">
                      Download Receipt
                    </a>{' '}
                  </div>
                </div>
              </div>
            )}
          {successDetail &&
            !successDetail.is_authenticated &&
            successDetail.transaction_id !== '' && (
              <div>
                <div className="d-flex justify-content-center">
                  <img src={fail} alt="fail" />
                </div>
                <div className="success-txt">Payment Failed!</div>
                <div className="success-amt">
                  {transactionData?.amount} {transactionData?.currency}
                </div>
                <div className="success-desc">
                  We're sorry, your card is being rejected by the bank.
                </div>
                <div className="text-center pt-3 link-success">
                    <a href="https://app.oneid.ink/" target="_blank">
                      Download Receipt
                    </a>{' '}
                  </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};
