import { createApiInstance } from "./apiInstance";
import { apiCall } from "./apiCall";
import { Method } from "axios";

const frimiApi = createApiInstance(process.env.SERVER_FRIMI_URL || "https://onepay-frimi-live-dot-spemai-asia.el.r.appspot.com/api/");

export const frimiApiCall = (
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false
): Promise<any> => {
  return apiCall(frimiApi, url, method, data, header, encrypt,false);
};
