import forge from 'node-forge';


const encryptMessage = (data: any) => {

    const publicKeyPem = `
    -----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuU5jIuZ00tSfszJR6H4i
    fAelmPJoHa+ybYQ5WkdyGh7Xy9ulX8rjgtUIn/hU8/s5CID3zhoPYnqx9CqQKpaK
    fgljAYdWNFBYHdnzAzOFQUTknd6BMFZLZ1DkY+pcYtn6iT9vXpn7SeoSanACZOip
    bYvMykCfliS3AdEly9aeWtJvA7pI01sPA0L4eknaSQ5zBCeDidDbuXCwXPMQEAde
    oRmMqAVGmUX4EuW8y3RuZbHPcvN5VnKdOOiTQa7TFI9kZMYL1qrH1ttwCyd3qTAM
    E0W6/gMusliKrZbl4BL3ug3VKo7ygnMaxSyoEYxZnx6j05pWaPhFuMY7fgcSqGiT
    gwIDAQAB
    -----END PUBLIC KEY-----
    `;
    
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const dataString = JSON.stringify(data);
    const encryptedData = publicKey.encrypt(dataString, 'RSA-OAEP');
  
    // Convert the encrypted data to Base64
    const encryptedBase64 = forge.util.encode64(encryptedData);
    return encryptedBase64;
  };
  
  // Decrypt a message with the private key
  const decryptMessage = (encryptedMessage: string, private_Key: string) => {
    const privateKey = forge.pki.privateKeyFromPem(private_Key);
  
      // Decode the Base64 encoded encrypted data
      const encryptedBytes = forge.util.decode64(encryptedMessage);
  
      // Decrypt the data
      const decryptedData = privateKey.decrypt(encryptedBytes, 'RSA-OAEP');
      return decryptedData;
  };
  
  export { encryptMessage, decryptMessage };