import { createApiInstance } from "./apiInstance";
import { apiCall } from "./apiCall";
import { Method } from "axios";

const oneticketApi = createApiInstance(process.env.ONETICKET_BASE_URL || "https://api.onepay.lk/v3/");

export const oneticketApiCall = (
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false,
  decrypt:boolean = false
): Promise<any> => {
  return apiCall(oneticketApi, url, method, data, header, encrypt,decrypt);
};
