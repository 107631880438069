import { sampathApiCall } from "../utils/api/sampathApi";


export const sampathVishwaAPI = async (pay_load:any,hash:string,token:any) => {
    try {
      const data = await sampathApiCall('ipg/gateway/request-sampath-transaction/?ha=' + hash,'PUT',pay_load,token,false);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
