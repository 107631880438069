import React from 'react';
import CryptoJS from 'crypto-js';
// import { useHistory } from 'react-router-dom';

const key = 'ddfbccae-b4c4-11';
const iv = 'ddfbccae-b4c4-11';

const aesEncrypt = (plaintext: any, key: any, iv: any) => {
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);
  let srcs = CryptoJS.enc.Utf8.parse(plaintext);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString();
};

const aesDecrypt = (ciphertext: any, key: any, iv: any) => {
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);
  let hexString = CryptoJS.enc.Hex.parse(ciphertext);
  let srcs = CryptoJS.enc.Base64.stringify(hexString);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let decrypt2 = decrypt.toString(CryptoJS.enc.Utf8);
  return decrypt2.toString();
};

const repairJson = (data: any) => {
  let str, obj;
  data = replaceAll(data, 'True', 'true');
  data = replaceAll(data, 'False', 'false');

  try {
    str = data.replace(/'/g, '"');
    str = str.replace(/Decimal\("(\d+\.\d+)"\)/g, '$1');
    obj = JSON.parse(str);
  } catch (e) {
    try {
      obj = (0, eval)('(' + data + ')');
    } catch (e) {
      obj = {};
    }
  }

  return obj;
};

const replaceAll = (str: string, find: string, replace: string) => {
  var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  return str.replace(new RegExp(escapedFind, 'g'), replace);
};

const EncriptionService = () => {
  // const history = useHistory();

  const responseDecrypt = (response: any) => {

    try {
      let plaintext = aesDecrypt(response, key, iv);

      return JSON.parse(JSON.stringify(repairJson(plaintext), null, 2));
    } catch (error) {
      // history.push('/unauth');
      return false;
    }
  };

  const requestEncrypt = (jsonObj: any) => {
    try {
      let jsonStr = JSON.stringify(jsonObj);
      let ciphertext = aesEncrypt(jsonStr, key, iv);
      return ciphertext;
    } catch (error) {
      return '';
    }
  };

  return { responseDecrypt, requestEncrypt };
};

export default EncriptionService;
