import { BsArrowRightShort } from 'react-icons/bs';
import { Button } from '../../core/Button/Button';
import { TransactionDetails } from '../../types/api.types';
import { useSelector } from 'react-redux';
import { sampathVishwaAPI } from '../../services/sampath.service';
import { getJsonHash } from '../../utils/hashing.utils';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { oneticketValidateAPI } from '../../services/oneticket.service';

export const Vishwa = () => {
  const [isLoading,setIsLoading]=useState(false);
  const paymentDetails: TransactionDetails = useSelector(
    (state: any) => state.payment
  );

  const submitSampathVishwa = async () => {
    setIsLoading(true)

        // if (paymentDetails.request_type == "TICKETING") {
          let oneticketData = {
            onepay_transaction_id: paymentDetails.gateway_transaction_id,
          };
        
          const validate_response: any = await oneticketValidateAPI(oneticketData);
          if (validate_response.status !== 200) {
            toast.error("Sorry you took too long to submit payment.", { autoClose: 3000 });
        
            // Add a small delay before redirecting
            setTimeout(() => {
              window.location.href = paymentDetails?.redirect_url;
            }, 3000); // Adjust the delay as needed for your toast duration
        
            return;
          }

          
    let request = {
      onepay_transaction_id: paymentDetails.gateway_transaction_id,
      app_id: paymentDetails.app_id,
    };
    let hash_value = await getJsonHash(request);
    const sampath_response = await sampathVishwaAPI(
      request,
      hash_value,
      paymentDetails.security_key
    );

    if (sampath_response && sampath_response['data']) {
      // Open the link in a new window with specified features
      window.location.href=sampath_response['data']
      // window.open(sampath_response['data'], '_blank', 'noopener,noreferrer,width=800,height=600');
      // Optionally, notify the user
    }else{
      setIsLoading(false)
      toast.error(sampath_response.message)
    }
  
  };
  return (
    <div>
      <div className="ps-3 pe-3 pt-3">
        <Button
           buttonText={paymentDetails.action_bt_text}
          type="button"
          onClickHandler={() => submitSampathVishwa()}
          icon={<BsArrowRightShort />}
          style={{backgroundColor:paymentDetails.action_button_bt_color}}
          className="pay-btn"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
