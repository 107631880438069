
import { helaPayApiCall } from "../utils/api/helaPayApi";



export const helapaySubmitAPI = async (pay_load:any) => {
    try {
      const data = await helaPayApiCall('helapay/1.0/transaction/','POST',pay_load,'',false);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
