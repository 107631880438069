import { createApiInstance } from "./apiInstance";
import { apiCall } from "./apiCall";
import { Method } from "axios";
import { decrypt } from "dotenv";

const cardApi = createApiInstance(process.env.SERVER_MPGS_URL || "https://onepay-mpgs-live-dot-spemai-asia.el.r.appspot.com/api/v3/");

export const cardPaymentApiCall = (
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false,
  decrypt: boolean = false
): Promise<any> => {
  return apiCall(cardApi, url, method, data, header, encrypt,decrypt);
};
