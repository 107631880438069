import { lankaqrApiCall } from "../utils/api/lankaqrApi";

export const lankaqrGetAPI = async (pay_load:any) => {
    try {
      const data = await lankaqrApiCall('lankaqr/1.0/qr/' ,'POST',pay_load,null,false);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }