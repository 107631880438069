import { notifyDetails } from "../../types/api.types";
import { SET_NOTIFY_DETAILS } from "../NotifyAction";


// Define the initial state with the TransactionDetails type
const initialState: notifyDetails = {
  is_loading:false,
    is_authenticated: false,
    is_pay: false,
    transaction_id: '',
  };

  const notifyReducer = (state: notifyDetails = initialState, action: any): notifyDetails => {
    switch (action.type) {
      case SET_NOTIFY_DETAILS:
        return {
          ...state,
          ...action.payload, // Merge the existing state with the new values
        };
      default:
        return state;
    }
  };
  
  export default notifyReducer;
  